import React, { useState, useEffect } from "react";
import Sheet from "@mui/joy/Sheet";
import { CssVarsProvider } from "@mui/joy/styles";
import { Routes, Route, useNavigate, useLocation, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import axios from "axios";
import JSConfetti from "js-confetti";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//all components
import Nav from "./components/Nav";
import LandingPage from "./components/LandingPage";
import HomeSchool from "./components/Marketplace/HomeSchool.js";
import HomeNPO from "./components/Marketplace/HomeNPO.js";
import Entry from "./components/Entry.js";
import Profile from "./components/Profile";
import Training from "./components/Marketplace/Training.js";
import CreateAsk from "./components/Marketplace/CreateAsk.js";
import Accepted from "./components/Marketplace/Accepted.js";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Signup from "./components/Signup";
import OrgAsks from "./components/Marketplace/OrgAsks.js";
import Inventory from "./components/Inventory/Inventory.js";
import AddInventory from "./components/Inventory/AddInventory.js";
import Ask from "./components/Marketplace/Ask.js";
import Payment from "./components/Marketplace/Payment.js";
import CheckoutForm from "./components/Marketplace/CheckoutForm.js";
import Completion from "./components/Marketplace/Completion.js";
import GuestCheckout from "./components/Marketplace/GuestCheckout.js";
import AboutUs from "./components/AboutUs.js";
import OrderDetails from "./components/Marketplace/OrderDetails.js";

const { ip } = require("./ip.js");

function App() {
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  const confetti = new JSConfetti();
  let location = useLocation();
  const [user, setUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [checkedStates, setCheckedStates] = useState({});
  const [statusClick, setStatusClick] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [tenant, setTenant] = useState(location.pathname.split("/")[1]);

  const Success_Message = (message, ask_id) => {
    if (message === "Ask Accepted!") {
      //toast message, and add button in message to navigate to accepted page
      toast.success(message + " Click Here to View", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClick: () => {
          navigate(`/${user.tenant}/accepted`, { replace: true, state: {scrollTo: ask_id} });
        },
      });
    } else {
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const Error_Message = (message) => {
    toast.error(message, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  function showLoggedOut() {
    if (location && location.state && location.state.loggedOut) {
      toast.success("Logged Out Successfully!", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "logged out",
        onChange: () => {
          location.state.loggedOut = false;
        },
      });
    }
  }

  const checkLoginStatus = async () => {
    try {
      const response = await axios.get(`${ip}/login/login`, {
        withCredentials: true,
      });

      if (response.data.loggedIn) {
        setIsAuthenticated(true);
        setUser(response.data.user);
        navigate(1); // Navigate forward once logged in
        return;
      } 
      else {
        //TEMP FIX, NEED MORE PERMANENT SOLUTION
        if (!tenant) {
          navigate(`/${ip == "http://localhost:3001" ? "wlc" : "dpscd"}`, {
            replace: true,
          });
          setTenant(ip == "http://localhost:3001" ? "wlc" : "dpscd");
          return;
        }
        //if user tries to access browse or contact, they should not be redirected to login
        const publicRoutes = ["browse", "contact", "donate", "login"];
        if (publicRoutes.includes(location.pathname.split("/")[2])) {
          navigate(`/${tenant}/${location.pathname.split("/")[2]}`);
        } else {
          navigate(`/${tenant}`, { replace: true });
        }
      }
    } catch (error) {
      console.error("Error checking login status:", error);
    }
  };

  useEffect(() => {
    axios
      .get(`${ip}/tenants`)
      .then((response) => {
        if (response.data) {
          setTenants(response.data);
        }
      })
      .then(() => {
        checkLoginStatus();
      });
  }, []); // Dependencies include tenants and ip

  const handleCheckBoxChange = (
    e,
    askIndex,
    productIndex,
    item,
    quantity,
    status
  ) => {
    setCheckedStates((prevStates) => {
      const newStates = [...prevStates];
      //if askIndex larger than newStates length, add new array with lenth of productIndex + 1
      //for orgAsks edge case
      if (user["account_type"] === "Admin") {
        if (askIndex >= newStates.length) {
          newStates.push(
            new Array(productIndex + 1).fill({
              id: null,
              product_name: null,
              quantity: null,
              acceptance_data: null,
            })
          );
        }
      }

      newStates[askIndex][productIndex] = e?.target?.checked
        ? {
            id: item.ask_id,
            product_name: item.product_name,
            quantity,
            status,
            acceptance_data: item.acceptance_data,
          }
        : {
            id: null,
            product_name: null,
            quantity: null,
            acceptance_data: null,
          };
      return newStates;
    });
  };

  function addToInventory(product_name, quantity, organization, ask_id) {
    axios
      .post(`${ip}/inventory/received`, {
        product_name: product_name,
        quantity: quantity,
        date_received: new Date(),
        organization: organization,
        ask_id: ask_id,
      })
      .then((response) => {
        if (response.data) {
          Success_Message("Added to Inventory!");
          setStatusClick(!statusClick);
        }
      });
  }

  function clean_data(data, status) {
    let clean_data = data;
    if (status === 0) {
      //remove empty objects
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === "object" && value !== null) {
          if (value.id === null) {
            delete clean_data[key];
          }
        }
      }
    } else {
      for (const [key, value] of Object.entries(data)) {
        if (!value.id && (value.status !== null || value.status !== 0)) {
          clean_data[key] = {
            id: null,
            product_name: null,
            quantity: null,
            accepted: null,
          };
        }
      }
    }
    return clean_data;
  }

  //SCHOOL FUNCTION
  function changeMultipleStatus(id, group, new_status, old_status) {
    axios
      .put(`${ip}/asks/changeMultipleStatus/`, {
        id: id,
        group: clean_data(group.products ? group.products : group, old_status),
        new_status: new_status,
        tenant: user.tenant || tenant,
      })
      .then((response) => {
        if (response.data) {
          setStatusClick(!statusClick);
        }
      })
      .then(() => {
        Success_Message("Completed! Adding to Inventory...");
        //loop through group and add each product to inventory
        for (const [key, value] of Object.entries(
          group.products ? group.products : group
        )) {
          if (typeof value === "object" && value !== null) {
            addToInventory(
              value.product_name,
              value.quantity,
              user.organization,
              value.id
            );
          }
        }
      });
  }

  return (
    <CssVarsProvider>
      <Sheet
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
        }}
      >
        {isAuthenticated ? (
          <>
            <Nav
              user={user}
              setUser={setUser}
              isAuthenticated={isAuthenticated}
              setIsAuthenticated={setIsAuthenticated}
              isMobile={isMobile}
              tenant={tenant}
            />
            <Routes>
              {user["account_type"] === "School" ||
              user["account_type"] === "Admin" ? (
                //navigator, admin, user that create asks
                <>
                  <Route
                    path="/:tenant/landingPage"
                    element={
                      <LandingPage
                        user={user}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/home"
                    element={
                      <HomeSchool
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        confetti={confetti}
                        isAuthenticated={isAuthenticated}
                        checkedStates={checkedStates}
                        setCheckedStates={setCheckedStates}
                        handleCheckBoxChange={handleCheckBoxChange}
                        clean_data={clean_data}
                        changeMultipleStatus={changeMultipleStatus}
                        addToInventory={addToInventory}
                        statusClick={statusClick}
                        setStatusClick={setStatusClick}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/create"
                    element={
                      <CreateAsk
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/inventory"
                    element={
                      <Inventory
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/addInventory"
                    element={
                      <AddInventory
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/ask/:id"
                    element={
                      <Ask
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isAuthenticated={isAuthenticated}
                        confetti={confetti}
                        checkedStates={checkedStates}
                        setCheckedStates={setCheckedStates}
                        handleCheckBoxChange={handleCheckBoxChange}
                        clean_data={clean_data}
                        changeMultipleStatus={changeMultipleStatus}
                        addToInventory={addToInventory}
                        statusClick={statusClick}
                        setStatusClick={setStatusClick}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/profile"
                    element={
                      <Profile
                        user={user}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/training"
                    element={
                      <Training
                        user={user}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  {user["account_type"] === "Admin" ? (
                    <Route
                      path="/:tenant/orgAsks"
                      element={
                        <OrgAsks
                          user={user}
                          Success_Message={Success_Message}
                          Error_Message={Error_Message}
                          confetti={confetti}
                          isAuthenticated={isAuthenticated}
                          checkedStates={checkedStates}
                          setCheckedStates={setCheckedStates}
                          handleCheckBoxChange={handleCheckBoxChange}
                          clean_data={clean_data}
                          changeMultipleStatus={changeMultipleStatus}
                          addToInventory={addToInventory}
                          statusClick={statusClick}
                          setStatusClick={setStatusClick}
                          isMobile={isMobile}
                          tenant={tenant}
                        />
                      }
                    />
                  ) : null}
                </>
              ) : user["account_type"] === "NPO" ||
                user["account_type"] === "member" ? (
                  //NPO, member, user that accept asks
                <>
                  <Route
                    path="/:tenant/landingPage"
                    element={
                      <LandingPage
                        user={user}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/donate"
                    element={
                      <Payment
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isMobile={isMobile}
                        isAuthenticated={isAuthenticated}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/checkoutForm"
                    element={
                      <CheckoutForm
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isMobile={isMobile}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/completion"
                    element={
                      <Completion
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isMobile={isMobile}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/home"
                    element={
                      <HomeNPO
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isAuthenticated={isAuthenticated}
                        confetti={confetti}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/accepted"
                    element={
                      <Accepted
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isAuthenticated={isAuthenticated}
                        confetti={confetti}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/profile"
                    element={
                      <Profile
                        user={user}
                        Success_Message={Success_Message}
                        Error_Message={Error_Message}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                  <Route
                    path="/:tenant/training"
                    element={
                      <Training
                        user={user}
                        isMobile={isMobile}
                        tenant={tenant}
                      />
                    }
                  />
                </>
              ) : (
                <Route
                  path="/:tenant/landingPage"
                  element={
                    <LandingPage
                      user={user}
                      isAuthenticated={isAuthenticated}
                      isMobile={isMobile}
                      tenant={tenant}
                    />
                  }
                />
              )}
            </Routes>
          </>
        ) : (
          //GUEST, not logged in, can accept asks
          <>
            <Nav
              user={user}
              setUser={setUser}
              isAuthenticated={isAuthenticated}
              setIsAuthenticated={setIsAuthenticated}
              isMobile={isMobile}
              tenant={tenant}
            />
            <Routes>
              <Route
                path="/:tenant/login"
                element={
                  <Login
                    user={user}
                    setUser={setUser}
                    setIsAuthenticated={setIsAuthenticated}
                    isMobile={isMobile}
                    tenant={tenant}
                    setTenant={setTenant}
                  />
                }
              />
              {/* <Route
                path="/signup"
                element={
                  <Signup
                    user={user}
                    setUser={setUser}
                    setIsAuthenticated={setIsAuthenticated}
                    isMobile={isMobile}
                    tenant={tenant}
                    setTenant={setTenant}
                  />
                }
              /> */}
              <Route
                path="/:tenant/browse"
                element={
                  <HomeNPO
                    user={user}
                    Success_Message={Success_Message}
                    Error_Message={Error_Message}
                    confetti={confetti}
                    isMobile={isMobile}
                    tenant={tenant}
                    guest={true}
                  />
                }
              />
              <Route
                path="/:tenant/contact"
                element={
                  <Contact
                    Success_Message={Success_Message}
                    Error_Message={Error_Message}
                    isMobile={isMobile}
                    tenant={tenant}
                  />
                }
              />
              <Route
                path="/:tenant/about-gyftbay"
                element={
                  <AboutUs
                    Success_Message={Success_Message}
                    Error_Message={Error_Message}
                    isMobile={isMobile}
                    tenant={tenant}
                  />
                }
              />
              <Route
                path="/:tenant/order-details/:id"
                element={
                  <OrderDetails
                    Success_Message={Success_Message}
                    Error_Message={Error_Message}
                    isMobile={isMobile}
                    tenant={tenant}
                  />
                }
              />
              <Route
                path="/:tenant"
                element={
                  <Entry
                    user={user}
                    setUser={setUser}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    showLoggedOut={showLoggedOut}
                    isMobile={isMobile}
                    tenant={tenant}
                  />
                }
              />
              <Route
                path="/:tenant/donate"
                element={
                  <Payment
                    user={user}
                    Success_Message={Success_Message}
                    Error_Message={Error_Message}
                    isMobile={isMobile}
                    isAuthenticated={isAuthenticated}
                    tenant={tenant}
                  />
                }
              />
              <Route
                path="/:tenant/checkoutForm"
                element={
                  <CheckoutForm
                    user={user}
                    Success_Message={Success_Message}
                    Error_Message={Error_Message}
                    isMobile={isMobile}
                    tenant={tenant}
                  />
                }
              />
              <Route
                path="/:tenant/completion"
                element={
                  <Completion
                    user={user}
                    Success_Message={Success_Message}
                    Error_Message={Error_Message}
                    isMobile={isMobile}
                    tenant={tenant}
                  />
                }
              />
              <Route
                path="/:tenant/guest-checkout"
                element={
                  <GuestCheckout
                    user={user}
                    isAuthenticated={isAuthenticated}
                    Success_Message={Success_Message}
                    Error_Message={Error_Message}
                    isMobile={isMobile}
                    tenant={tenant}
                    checkedStates={checkedStates}
                  />
                }
              />
            </Routes>
          </>
        )}
      </Sheet>
    </CssVarsProvider>
  );
}

export default App;
