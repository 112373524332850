import React, { useState, useEffect } from "react";
import axios from "axios";
import About from "./About.js";
import Product from "./Product.js";
import {
  Card,
  Stack,
  Button,
  Typography,
  CircularProgress,
  Modal,
  ModalDialog,
  ModalClose,
  Input,
  Box,
  Table
} from "@mui/joy/";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
const { ip } = require("../../ip.js");

function HomeNPO(props) {
  axios.defaults.withCredentials = true;
  const user = props.user;
  const [data, setData] = useState();
  const [ids, setIds] = useState([]);
  const [statusClick, setStatusClick] = useState(false);
  const [checkedStates, setCheckedStates] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState([]); // Array to track modal open/close states
  const Success_Message = props.Success_Message;
  const Error_Message = props.Error_Message;
  const confetti = props.confetti;
  const isAuthenticated = true;
  const [budgetModal, setBudgetModal] = useState(false);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [products, setProducts] = useState(null);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("10000");
  const [loading, setLoading] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [minPriceInput, setMinPriceInput] = useState("");
  const [maxPriceInput, setMaxPriceInput] = useState("");
  const [budget, setBudget] = useState(0);
  const [budgetItems, setBudgetItems] = useState(null);
  const tenant = props.tenant;
  const [isChoosingItems, setIsChoosingItems] = useState(false);
  const isMobile = props.isMobile;
  const guest = props.guest;
  const navigate = useNavigate();

  const tenant_map = {
    "nammy-ryuji-wedding": "Nammy and Ryuji's Wedding",
    "dpscd": "Detroit Public Schools Community District",
    "wlc": "Walled Lake Central"
  };

  function find_checked_items(checked) {
    //loop through checkedStates and find all items that have been checked
    //return an array of all checked items
    let checkedItems = [];
    for (let i = 0; i < checked.length; i++) {
      for (let j = 0; j < checked[i].length; j++) {
        if (
          checked[i][j].id !== null &&
          checked[i][j].requested_quantity &&
          checked[i][j].requested_quantity > 0
        ) {
          checkedItems.push(checked[i][j]);
        }
      }
    }
    return checkedItems;
  }

  function Email(
    to_email_id,
    from_email,
    from_name,
    from_organization,
    content,
    status,
    ask_id
  ) {
    axios.get(`${ip}/email/${to_email_id}`).then((response) => {
      if (response.data) {
        emailjs
          .send(
            "service_f5sai5q",
            "template_bs16y39",
            {
              to_email: response.data.email,
              from_email: from_email,
              from_name: from_name,
              from_organization: from_organization || "N/A",
              content: content,
              status: status,
              ask_id: ask_id,
            },
            "x7LM43rlkJsqov2r7"
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("email sent");
            } else {
              console.log("email not sent");
            }
          });
      }
    });
  }

  function validate_accept(group) {
    //loop through group and check if any are null,
    //also check if requested quantity is less than or equal to remaining quantity or less than  or equal to0
    for (let i = 0; i < group.length; i++) {
      if (group[i].id !== null) {
        if (
          group[i].requested_quantity > group[i].remaining_quantity ||
          group[i].requested_quantity <= 0
        ) {
          return false;
        }
      }
    }
    return true;
  }

  function changeMultipleStatus(id, group, new_status) {
    if (!validate_accept(group)) {
      Error_Message("Please select valid quantities");
      return;
    }
    axios
      .put(`${ip}/asks/changeMultipleStatus/`, {
        id: id,
        group: group,
        new_status: new_status,
        tenant: tenant,
      })
      .then((response) => {
        setStatusClick(!statusClick);
      })
      .then(() => {
        if (new_status === 1) {
          Success_Message("Ask Accepted!", group[0]?.id);
          confetti.addConfetti();
          setSearchQuery("");
          // this.forceUpdate();
        }
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${ip}/asks/allAsks/${user.tenant || tenant}`
        );
        setData(response.data.data);
        //setIds and order the ids such that the addresses are sorted in alphabetical order
        setIds(response.data.sortedAskIds);

        const initialCheckedStates = response.data.data.map((group) =>
          Array(group.products.length).fill({
            id: null,
            product_name: null,
            requested_quantity: null,
            remaining_quantity: null,
            link: null,
            address: null,
          })
        );
        setCheckedStates(initialCheckedStates);
        setModalOpen(Array(response.data.data.length).fill(false));

        const products = await axios.get(`${ip}/products/`);
        setProducts(products.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [statusClick, user.tenant, tenant]);

  useEffect(() => {
    if (!data) return;

    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    const filtered = data.filter(
      (group) =>
        (group?.ask[0].addr &&
          group?.ask[0].addr.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group?.ask[0].schoolAddr &&
          group?.ask[0].schoolAddr
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (group.ask[0].created &&
          group.ask[0].created.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].organization &&
          group.ask[0].organization
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (group.ask[0].school &&
          group.ask[0].school.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].healthhub &&
          group.ask[0].healthhub
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        group.products.some((product) =>
          product.product_name.toLowerCase().includes(lowerCaseSearchQuery))
    );

    console.log('filtered', filtered);

    if (filtered.length !== 0) {
      setFilteredData(filtered);
    }
    else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);

  useEffect(() => {
    setEstimatedPrice(0);
    for (const ask of checkedStates) {
      for (const item of ask) {
        if (item.id !== null) {
          const product = products.find(
            (product) => product.productName === item.product_name
          );
          if (
            item?.requested_quantity &&
            item?.remaining_quantity &&
            item?.requested_quantity <= item?.remaining_quantity
          ) {
            setEstimatedPrice((prevPrice) => {
              const updatedPrice =
                Number(prevPrice) +
                product.price *
                  (item.requested_quantity ? item.requested_quantity : 0);
              //add comma to price if val is greater than 999
              if (updatedPrice > 999) {
                return updatedPrice
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }

              return updatedPrice.toFixed(2);
            });
          }
        }
      }
    }
  }, [checkedStates, products]);

  useEffect(() => {
    if (isChoosingItems && budgetItems) {
      if (guest) {
        navigate(`/${tenant}/guest-checkout`, {
          state: {
            checkedItems: budgetItems,
            estimatedPrice: estimatedPrice !== 0 ? estimatedPrice : budget,
          },
        });
      } 
      else {
        changeMultipleStatus(user.id, budgetItems, 1);
      }
      setIsChoosingItems(false); // Reset after handling
    }
  }, [isChoosingItems, checkedStates, budget]);


  const handleCheckBoxChange = (e, askIndex, productIndex, item) => {
    if (e.target.type === "number") {
      const newQuantity = parseInt(e.target.value);
      setCheckedStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[askIndex][productIndex] = {
          id: item.ask_id,
          product_name: item.product_name,
          remaining_quantity: item.remaining_quantity,
          requested_quantity: newQuantity,
          link: item.link,
          address: item.address,
        };
        return newStates;
      });
      return;
    } else {
      setCheckedStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[askIndex][productIndex] = e.target.checked
          ? {
              id: item.ask_id,
              product_name: item.product_name,
              remaining_quantity: item.remaining_quantity,
              link: item.link,
              address: item.address,
            }
          : {
              id: null,
              product_name: null,
              remaining_quantity: null,
              requested_quantity: null,
              link: null,
              address: null,
            };
        return newStates;
      });
    }
  };

  if (!data) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          margin: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const show_loading = () => {
    setLoading(true);
  };

  const hide_loading = () => {
    setLoading(false);
  };

  function hide_accept_all_button(askIndex) {
    //check if any item has been checked
    for (let i = 0; i < checkedStates[askIndex].length; i++) {
      if (checkedStates[askIndex][i]?.id) {
        return true;
      }
    }
    return false;
  }


  const choose = async (ids) => {
    //loop through products of ask given ask_id
    //if cost of items of fit the budget, select items
    //if not, return false
    setBudgetItems(null);
    try {
      let index = 0;
      let totalCost = 0;
      const selectedItems = [];
      //loop through ids, add items to selectedItems until totalCost is greater than budget
      while ((totalCost < budget * 0.9) && (ids.length > 0) && (ids[index] !== undefined)) {
        const ask = filteredData.find(
          (group) => group.ask[0].id === ids[index]
        );

        if (!ask) {
          return false;
        }

        for (const item of ask?.products) {
          const maxQuantityAffordable = Math.min(
            Math.floor((budget - totalCost) / Number(item.price)),
            item.remaining_quantity
          );

          if (maxQuantityAffordable > 0) {
            selectedItems.push({
              ask_id: item.ask_id,
              product_name: item.product_name,
              requested_quantity: maxQuantityAffordable,
              remaining_quantity: item.remaining_quantity,
              address: item.address,
              link: item.link,
              price: item.price,
              total: (maxQuantityAffordable * Number(item.price)).toFixed(2),
            });
            totalCost += maxQuantityAffordable * Number(item.price);

            if (totalCost <= budget) {
              setEstimatedPrice(totalCost);
            } 
            else if (totalCost >= budget * 0.9) {
              setEstimatedPrice(totalCost);
              break;
            }
          }
        }

        if (selectedItems.length === 0) {
          Error_Message("Unable to select items within the budget.");
          return;
        }

        ids.splice(index, 1);
      }

      
      setBudgetItems(selectedItems)
      Success_Message("Items selected based on your budget!");
      setStatusClick(!statusClick);

    } finally {
      if (guest) {
        setIsChoosingItems(true); // Reset to false
      } 
    }
  }

  //filter data given the min and max price
  //if min and max price are empty, return data
  //if returned data is empty, return original data
  const filter_data = () => {
    if (minPrice === "" && maxPrice === "") {
      return data;
    }
    let filtered = filteredData;
    if (minPrice !== "") {
      filtered = filtered.map((group) => {
        return {
          ask: group.ask,
          products: group.products.filter((product) => {
            const productData = products.find(
              (p) => p.productName === product.product_name
            );
            return productData.price >= Number(minPrice);
          }),
        };
      });
    }
    if (maxPrice !== "") {
      filtered = filtered.map((group) => {
        return {
          ask: group.ask,
          products: group.products.filter((product) => {
            const productData = products.find(
              (p) => p.productName === product.product_name
            );
            return productData.price <= Number(maxPrice);
          }),
        };
      });
    }
    return filtered;
  }

  

  return (
    <Box sx={{ px: { xs: 2, md: 6 }, pb: 12, minHeight: "100vh" }}>
      <Stack spacing={4} direction={"column"}>
        {loading && <CircularProgress />}
        <ToastContainer />
        {!isAuthenticated ? (
          <Typography color="danger" level="h4">
            Please Login to accept asks
          </Typography>
        ) : null}
        <Stack spacing={3} direction="column" justifyContent="space-between">
          <Typography level="h1">All Asks</Typography>
          <Typography
            level="body1"
            sx={{ color: "text.secondary", maxWidth: "800px" }}
          >
            <b>Directions: </b>This page shows all current asks for{" "}
            {tenant_map[tenant]}. You can search for specific items, filter by
            price, or accept asks directly. To accept an ask, select items from
            the list by clicking the checkbox, choose a quantity, and click
            "Accept Selected".
          </Typography>
        </Stack>
        {filteredData.length !== 0 && (
          <Stack direction="column" spacing={2}>
            {/* Search and Filter Section */}
            <Stack
              direction="column"
              spacing={2}
              sx={{
                width: "100%",
                alignItems: "stretch",
              }}
            >
              <Typography level="body-lg" sx={{ mb: 1 }}>
                Search By:
              </Typography>
              <Input
                size="md"
                placeholder="Item, Date, Address, School, Organization"
                id="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ width: isMobile ? "100%" : "25%" }}
              />

              <Typography level="body-lg" sx={{ mt: 2 }}>
                Filter By Price:
              </Typography>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <Input
                  size="md"
                  placeholder="Min Price"
                  startDecorator="$"
                  value={minPriceInput}
                  slotProps={{
                    input: {
                      min: 1,
                      step: 1,
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    setMinPrice(value === "" ? "" : value);
                    setMinPriceInput(value);
                  }}
                  sx={{ width: isMobile ? "100%" : 150 }}
                  type="number"
                />
                <Input
                  size="md"
                  placeholder="Max Price"
                  startDecorator="$"
                  value={maxPriceInput}
                  onChange={(e) => {
                    const value = e.target.value;
                    setMaxPrice(value === "" ? "" : value);
                    setMaxPriceInput(value);
                  }}
                  sx={{ width: isMobile ? "100%" : 150 }}
                  slotProps={{
                    input: {
                      max: 10000,
                      step: 1,
                    },
                  }}
                  type="number"
                />
              </Stack>

              <Stack
                direction={isMobile ? "column" : "row"}
                spacing={2}
                sx={{ mt: 2 }}
              >
                {minPrice !== "" ||
                (maxPrice !== "10000" && maxPrice !== "") ? (
                  <Button
                    color="primary"
                    sx={{ width: isMobile ? "100%" : 150 }}
                    onClick={() => {
                      const filtered = filter_data();
                      show_loading();
                      setShowReset(true);
                      setTimeout(() => {
                        setFilteredData(filtered);
                        hide_loading();
                        Success_Message("Filter Applied!");
                      }, 1000);
                    }}
                  >
                    Filter
                  </Button>
                ) : null}
                {showReset && (
                  <Button
                    color="danger"
                    onClick={() => {
                      setMinPrice("");
                      setMaxPrice("10000");
                      setFilteredData(data);
                      setShowReset(false);
                      setMinPriceInput("");
                      setMaxPriceInput("");
                    }}
                    sx={{ width: isMobile ? "100%" : 150, height: 30 }}
                    size="md"
                  >
                    Reset
                  </Button>
                )}
              </Stack>
            </Stack>

            {/* Budget Section */}
            <Stack
              direction="column"
              spacing={2}
              alignItems="stretch"
              sx={{ mt: 3 }}
            >
              <Typography level="body-lg">
                Not sure what to buy? Enter your budget and we'll choose the
                items for you!
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Input
                  placeholder="Budget"
                  startDecorator="$"
                  value={budget}
                  onChange={(e) => {
                    const value = e.target.value;
                    setBudget(value);
                  }}
                  sx={{ width: isMobile ? "100%" : 150 }}
                  slotProps={{
                    input: {
                      max: 10000,
                      min: 1,
                      step: 1,
                    },
                  }}
                  type="number"
                />
                {budget && budget > 0 ? (
                  <Button
                    color="success"
                    onClick={() => {
                      if (!guest) {
                        setBudgetModal(true);
                      } 
                      choose(ids);
                    }}
                    sx={{ width: isMobile ? "100%" : 150 }}
                  >
                    Checkout
                  </Button>
                ) : null}
                {budgetModal && (
                  <Modal
                    open={budgetModal}
                    onClose={() => setBudgetModal(false)}
                  >
                    <ModalDialog>
                      <Stack spacing={2} alignContent={"center"} justifyContent={"center"}>
                        <Typography level="body-md" textAlign={'center'}>
                          These are the items we've selected for you based on your budget. If you're happy with the selection, click "Accept". If not, you can click "Cancel" and adjust your budget or choose items directly.
                        </Typography>
                        <Table
                          sx={{
                            textAlign: "left",
                            marginTop: "16px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "30%" }}>Item</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {budgetItems?.map((item, index) => (
                              <tr key={index}>
                                <td>{item.product_name}</td>
                                <td>{item.requested_quantity}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Stack
                          spacing={1}
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Button
                            color="success"
                            onClick={() => {
                              setIsChoosingItems(true)
                              setBudgetModal(false);
                            }}
                          >
                            Accept
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => setBudgetModal(false)}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </Stack>
                    </ModalDialog>
                  </Modal>
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
        {filteredData.length === 0 ? (
          <Card>
            <Typography level="body-lg">
              No Asks, check back later, or {" "} 
              <Button onClick={() => {
                setStatusClick(!statusClick);
              }}>
                Refresh Page
                </Button>
            </Typography>
          </Card>
        ) : (
          filteredData.map((group, index) => (
            <Stack spacing={1} key={index}>
              <Card
                color="neutral"
                invertedColors={false}
                orientation="vertical"
                size="md"
                variant="outlined"
              >
                <About
                  data={group.ask}
                  user={user}
                  isAuthenticated={isAuthenticated}
                />
                <Product
                  data={group.products}
                  user={user}
                  {...group.ask}
                  askIndex={index}
                  Success_Message={Success_Message}
                  Error_Message={Error_Message}
                  isAuthenticated={isAuthenticated}
                  confetti={confetti}
                  checked={checkedStates[index]} // Use the specific checked state for the ask
                  setCheckedStates={setCheckedStates}
                  handleCheckBoxChange={handleCheckBoxChange}
                  isMobile={isMobile}
                  guest={guest}
                />
                <Stack spacing={1} direction={"row"}>
                  {!guest &&
                  isAuthenticated &&
                  group &&
                  group.products &&
                  group.products.length > 1 &&
                  !hide_accept_all_button(index) ? (
                    <>
                      <Button
                        color="success"
                        onClick={() => {
                          setModalOpen((prevState) => {
                            const newState = [...prevState];
                            newState[index] = true;
                            return newState;
                          });
                        }}
                        sx={{ margin: 1, width: "auto" }}
                      >
                        Accept All
                      </Button>
                      <Modal
                        open={modalOpen[index]}
                        onClose={() =>
                          setModalOpen((prevState) => {
                            const newState = [...prevState];
                            newState[index] = false;
                            return newState;
                          })
                        }
                      >
                        <ModalDialog>
                          <ModalClose />
                          <Typography alignContent={"center"} level="body-lg">
                            Are you sure you want to accept all?
                          </Typography>
                          <Stack
                            spacing={1}
                            direction={"row"}
                            justifyContent={"center"}
                          >
                            <Button
                              color="success"
                              sx={{ width: "auto" }}
                              onClick={() => {
                                changeMultipleStatus(
                                  user.id,
                                  group.products,
                                  1
                                );
                              }}
                            >
                              Yes
                            </Button>
                            <Button
                              color="danger"
                              sx={{ width: "auto" }}
                              onClick={() =>
                                setModalOpen((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = false;
                                  return newState;
                                })
                              }
                            >
                              No
                            </Button>
                          </Stack>
                        </ModalDialog>
                      </Modal>
                    </>
                  ) : null}
                </Stack>
              </Card>
            </Stack>
          ))
        )}
        {estimatedPrice !== 0 && !budget && (
          <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent={"space-between"}
          >
            {/* First Card */}
            <Card
              sx={{
                position: "fixed",
                bottom: isMobile ? "unset" : "16px",
                top: isMobile ? "16px" : "unset",
                right: "16px",
                width: isMobile ? "90%" : "350px",
                boxShadow: "lg",
                borderRadius: "lg",
                padding: isMobile ? "16px" : "24px",
                backgroundColor: "#003A56",
                borderColor: "neutral.outlinedBorder",
                zIndex: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                level="h4"
                sx={{
                  fontWeight: "bold",
                  color: "neutral.100",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                Estimated Total
              </Typography>
              <Typography
                level="h3"
                sx={{
                  fontWeight: "bold",
                  color: "neutral.50",
                  textAlign: "center",
                }}
              >
                ${estimatedPrice}
              </Typography>
            </Card>

            {/* Second Card */}
            <Card
              sx={(theme) => ({
                position: "fixed",
                bottom: "16px",
                left: "16px",
                backgroundColor: "green",
                width: isMobile ? "90%" : "350px",
                boxShadow: "lg",
                borderRadius: "lg",
                padding: isMobile ? "16px" : "24px",
                borderColor: "neutral.outlinedBorder",
                zIndex: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                transition: "transform 0.3s, border 0.3s",
                "&:hover": {
                  borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                  transform: "translateY(-4px) scale(1.05)",
                },
                ":hover": {
                  cursor: "pointer",
                },
              })}
              onClick={() => {
                // user is not logged in
                if (guest) {
                  navigate(`/${tenant}/guest-checkout`, {
                    state: {
                      checkedItems: find_checked_items(checkedStates),
                      estimatedPrice: estimatedPrice,
                    },
                  });
                } else {
                  changeMultipleStatus(
                    user.id,
                    find_checked_items(checkedStates),
                    1
                  );
                }
              }}
            >
              <Typography
                level="h4"
                sx={{
                  fontWeight: "bold",
                  color: "neutral.100",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                Accept Selected
              </Typography>
            </Card>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default HomeNPO;
